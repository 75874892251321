import styled from '@emotion/styled';

// import responsive from '../../utils/responsive';
// import rhythm from '../../utils/rhythm';
// import * as colors from '../../styles/modules/colors.module.scss';

const Styles = styled.nav({
  /*[responsive.Desktop]: {
    a: {
      color: colors.primary,
    },
  },*/
});

export default Styles;
