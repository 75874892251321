// extracted by mini-css-extract-plugin
export var black = "#000";
export var blue = "#5d77cb";
export var bodyColor = "#222";
export var cyan = "#17a2b8";
export var danger = "#dc3545";
export var dark = "#333";
export var gray100 = "#f8f9fa";
export var gray200 = "#e9ecef";
export var gray300 = "#dee2e6";
export var gray400 = "#ced4da";
export var gray500 = "#adb5bd";
export var gray600 = "#666";
export var gray700 = "#444";
export var gray800 = "#333";
export var gray900 = "#222";
export var green = "#89c145";
export var indigo = "#6610f2";
export var info = "#17a2b8";
export var light = "#f8f9fa";
export var linkColor = "#5d77cb";
export var orange = "#fd7e14";
export var pink = "#e83e8c";
export var primary = "#5d77cb";
export var purple = "#6440ba";
export var red = "#dc3545";
export var secondary = "#666";
export var success = "#89c145";
export var teal = "#20c997";
export var warning = "#ffc107";
export var white = "#fff";
export var yellow = "#ffc107";